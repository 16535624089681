import { Nav } from 'react-bootstrap';
import NavigationArrow from '../../../components/NavigationArrow/NavigationArrow';
import { useMediaQuery } from 'react-responsive'


const TheDuet = () => {

  const isBigScreen = useMediaQuery({ query: '(min-width: 1101px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1100px)' })

  return ( 

    <div>

      <NavigationArrow />   

      {/* BIG SCREEN  */}

      {isBigScreen &&  

       <div className="banner" id="theduet-banner-background-bigscreen" >
        <svg className="banner-position" id="theduet-logo-bigscreen" width="1077" height="152" viewBox="0 0 1077 152" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="path-1-outside-1_7_7" maskUnits="userSpaceOnUse" x="0" y="0" width="1077" height="152" fill="black">
          <rect fill="white" width="1077" height="152"/>
          <path d="M10.4 5H127.6C129.067 5 130.333 5.53334 131.4 6.60001C132.467 7.66667 133 8.93335 133 10.4V42.6C133 44.0667 132.467 45.3333 131.4 46.4C130.333 47.4667 129.067 48 127.6 48H94.4V139.6C94.4 141.067 93.8667 142.333 92.8 143.4C91.7333 144.467 90.4667 145 89 145H49C47.5333 145 46.2667 144.467 45.2 143.4C44.1333 142.333 43.6 141.067 43.6 139.6V48H10.4C8.93333 48 7.66667 47.4667 6.6 46.4C5.53333 45.3333 5 44.0667 5 42.6V10.4C5 8.93335 5.53333 7.66667 6.6 6.60001C7.66667 5.53334 8.93333 5 10.4 5Z"/>
          <path d="M239.839 5H279.039C280.506 5 281.772 5.53334 282.839 6.60001C283.906 7.66667 284.439 8.93335 284.439 10.4V139.6C284.439 141.067 283.906 142.333 282.839 143.4C281.772 144.467 280.506 145 279.039 145H239.839C238.372 145 237.106 144.467 236.039 143.4C234.972 142.333 234.439 141.067 234.439 139.6V96.4H202.039V139.6C202.039 141.067 201.506 142.333 200.439 143.4C199.372 144.467 198.106 145 196.639 145H157.439C155.972 145 154.706 144.533 153.639 143.6C152.572 142.533 152.039 141.2 152.039 139.6V10.4C152.039 8.93335 152.572 7.66667 153.639 6.60001C154.706 5.53334 155.972 5 157.439 5H196.639C198.106 5 199.372 5.53334 200.439 6.60001C201.506 7.66667 202.039 8.93335 202.039 10.4V52.4H234.439V10.4C234.439 8.93335 234.972 7.66667 236.039 6.60001C237.106 5.53334 238.372 5 239.839 5Z"/>
          <path d="M358.438 106H420.038C421.504 106 422.771 106.533 423.837 107.6C424.904 108.667 425.438 109.933 425.438 111.4V139.6C425.438 141.067 424.904 142.333 423.837 143.4C422.771 144.467 421.504 145 420.038 145H315.837C314.371 145 313.104 144.467 312.038 143.4C310.971 142.333 310.438 141.067 310.438 139.6V10.4C310.438 8.93335 310.971 7.66667 312.038 6.60001C313.104 5.53334 314.371 5 315.837 5H418.438C419.904 5 421.171 5.53334 422.237 6.60001C423.304 7.66667 423.837 8.93335 423.837 10.4V38.6C423.837 40.0667 423.304 41.3333 422.237 42.4C421.171 43.4667 419.904 44 418.438 44H358.438V56.6H414.038C415.504 56.6 416.771 57.1333 417.838 58.2C418.904 59.2667 419.438 60.5333 419.438 62V88C419.438 89.4667 418.904 90.7333 417.838 91.8C416.771 92.8667 415.504 93.4 414.038 93.4H358.438V106Z"/>
          <path d="M512.908 5H570.508C589.974 5 605.974 9.86667 618.508 19.6C631.174 29.2 637.508 42.6667 637.508 60V89.8C637.508 107.933 631.374 121.667 619.108 131C606.841 140.333 590.708 145 570.708 145H512.908C511.441 145 510.174 144.467 509.108 143.4C508.041 142.333 507.508 141.067 507.508 139.6V10.4C507.508 8.93335 508.041 7.66667 509.108 6.60001C510.174 5.53334 511.441 5 512.908 5ZM557.508 44V106H570.508C575.041 106 578.641 104.667 581.308 102C584.108 99.2 585.508 95.1333 585.508 89.8V60C585.508 49.3334 580.508 44 570.508 44H557.508Z"/>
          <path d="M742.461 5H781.661C783.128 5 784.394 5.53334 785.461 6.60001C786.528 7.66667 787.061 8.93335 787.061 10.4V90.8C787.061 109.467 781.261 123.533 769.661 133C758.061 142.333 742.261 147 722.261 147C702.394 147 686.594 142.333 674.861 133C663.261 123.667 657.461 109.6 657.461 90.8V10.4C657.461 8.93335 657.994 7.66667 659.061 6.60001C660.128 5.53334 661.394 5 662.861 5H702.061C703.528 5 704.794 5.53334 705.861 6.60001C706.928 7.66667 707.461 8.93335 707.461 10.4V91.4C707.461 101.667 712.394 106.8 722.261 106.8C732.128 106.8 737.061 101.667 737.061 91.4V10.4C737.061 8.93335 737.594 7.66667 738.661 6.60001C739.728 5.53334 740.994 5 742.461 5Z"/>
          <path d="M859.023 106H920.623C922.09 106 923.357 106.533 924.423 107.6C925.49 108.667 926.023 109.933 926.023 111.4V139.6C926.023 141.067 925.49 142.333 924.423 143.4C923.357 144.467 922.09 145 920.623 145H816.423C814.957 145 813.69 144.467 812.623 143.4C811.557 142.333 811.023 141.067 811.023 139.6V10.4C811.023 8.93335 811.557 7.66667 812.623 6.60001C813.69 5.53334 814.957 5 816.423 5H919.023C920.49 5 921.757 5.53334 922.823 6.60001C923.89 7.66667 924.423 8.93335 924.423 10.4V38.6C924.423 40.0667 923.89 41.3333 922.823 42.4C921.757 43.4667 920.49 44 919.023 44H859.023V56.6H914.623C916.09 56.6 917.357 57.1333 918.423 58.2C919.49 59.2667 920.023 60.5333 920.023 62V88C920.023 89.4667 919.49 90.7333 918.423 91.8C917.357 92.8667 916.09 93.4 914.623 93.4H859.023V106Z"/>
          <path d="M948.486 5H1065.69C1067.15 5 1068.42 5.53334 1069.49 6.60001C1070.55 7.66667 1071.09 8.93335 1071.09 10.4V42.6C1071.09 44.0667 1070.55 45.3333 1069.49 46.4C1068.42 47.4667 1067.15 48 1065.69 48H1032.49V139.6C1032.49 141.067 1031.95 142.333 1030.89 143.4C1029.82 144.467 1028.55 145 1027.09 145H987.086C985.619 145 984.353 144.467 983.286 143.4C982.219 142.333 981.686 141.067 981.686 139.6V48H948.486C947.019 48 945.753 47.4667 944.686 46.4C943.619 45.3333 943.086 44.0667 943.086 42.6V10.4C943.086 8.93335 943.619 7.66667 944.686 6.60001C945.753 5.53334 947.019 5 948.486 5Z"/>
          </mask>
          <path d="M10.4 5H127.6C129.067 5 130.333 5.53334 131.4 6.60001C132.467 7.66667 133 8.93335 133 10.4V42.6C133 44.0667 132.467 45.3333 131.4 46.4C130.333 47.4667 129.067 48 127.6 48H94.4V139.6C94.4 141.067 93.8667 142.333 92.8 143.4C91.7333 144.467 90.4667 145 89 145H49C47.5333 145 46.2667 144.467 45.2 143.4C44.1333 142.333 43.6 141.067 43.6 139.6V48H10.4C8.93333 48 7.66667 47.4667 6.6 46.4C5.53333 45.3333 5 44.0667 5 42.6V10.4C5 8.93335 5.53333 7.66667 6.6 6.60001C7.66667 5.53334 8.93333 5 10.4 5Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_7_7)"/>
          <path d="M239.839 5H279.039C280.506 5 281.772 5.53334 282.839 6.60001C283.906 7.66667 284.439 8.93335 284.439 10.4V139.6C284.439 141.067 283.906 142.333 282.839 143.4C281.772 144.467 280.506 145 279.039 145H239.839C238.372 145 237.106 144.467 236.039 143.4C234.972 142.333 234.439 141.067 234.439 139.6V96.4H202.039V139.6C202.039 141.067 201.506 142.333 200.439 143.4C199.372 144.467 198.106 145 196.639 145H157.439C155.972 145 154.706 144.533 153.639 143.6C152.572 142.533 152.039 141.2 152.039 139.6V10.4C152.039 8.93335 152.572 7.66667 153.639 6.60001C154.706 5.53334 155.972 5 157.439 5H196.639C198.106 5 199.372 5.53334 200.439 6.60001C201.506 7.66667 202.039 8.93335 202.039 10.4V52.4H234.439V10.4C234.439 8.93335 234.972 7.66667 236.039 6.60001C237.106 5.53334 238.372 5 239.839 5Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_7_7)"/>
          <path d="M358.438 106H420.038C421.504 106 422.771 106.533 423.837 107.6C424.904 108.667 425.438 109.933 425.438 111.4V139.6C425.438 141.067 424.904 142.333 423.837 143.4C422.771 144.467 421.504 145 420.038 145H315.837C314.371 145 313.104 144.467 312.038 143.4C310.971 142.333 310.438 141.067 310.438 139.6V10.4C310.438 8.93335 310.971 7.66667 312.038 6.60001C313.104 5.53334 314.371 5 315.837 5H418.438C419.904 5 421.171 5.53334 422.237 6.60001C423.304 7.66667 423.837 8.93335 423.837 10.4V38.6C423.837 40.0667 423.304 41.3333 422.237 42.4C421.171 43.4667 419.904 44 418.438 44H358.438V56.6H414.038C415.504 56.6 416.771 57.1333 417.838 58.2C418.904 59.2667 419.438 60.5333 419.438 62V88C419.438 89.4667 418.904 90.7333 417.838 91.8C416.771 92.8667 415.504 93.4 414.038 93.4H358.438V106Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_7_7)"/>
          <path d="M512.908 5H570.508C589.974 5 605.974 9.86667 618.508 19.6C631.174 29.2 637.508 42.6667 637.508 60V89.8C637.508 107.933 631.374 121.667 619.108 131C606.841 140.333 590.708 145 570.708 145H512.908C511.441 145 510.174 144.467 509.108 143.4C508.041 142.333 507.508 141.067 507.508 139.6V10.4C507.508 8.93335 508.041 7.66667 509.108 6.60001C510.174 5.53334 511.441 5 512.908 5ZM557.508 44V106H570.508C575.041 106 578.641 104.667 581.308 102C584.108 99.2 585.508 95.1333 585.508 89.8V60C585.508 49.3334 580.508 44 570.508 44H557.508Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_7_7)"/>
          <path d="M742.461 5H781.661C783.128 5 784.394 5.53334 785.461 6.60001C786.528 7.66667 787.061 8.93335 787.061 10.4V90.8C787.061 109.467 781.261 123.533 769.661 133C758.061 142.333 742.261 147 722.261 147C702.394 147 686.594 142.333 674.861 133C663.261 123.667 657.461 109.6 657.461 90.8V10.4C657.461 8.93335 657.994 7.66667 659.061 6.60001C660.128 5.53334 661.394 5 662.861 5H702.061C703.528 5 704.794 5.53334 705.861 6.60001C706.928 7.66667 707.461 8.93335 707.461 10.4V91.4C707.461 101.667 712.394 106.8 722.261 106.8C732.128 106.8 737.061 101.667 737.061 91.4V10.4C737.061 8.93335 737.594 7.66667 738.661 6.60001C739.728 5.53334 740.994 5 742.461 5Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_7_7)"/>
          <path d="M859.023 106H920.623C922.09 106 923.357 106.533 924.423 107.6C925.49 108.667 926.023 109.933 926.023 111.4V139.6C926.023 141.067 925.49 142.333 924.423 143.4C923.357 144.467 922.09 145 920.623 145H816.423C814.957 145 813.69 144.467 812.623 143.4C811.557 142.333 811.023 141.067 811.023 139.6V10.4C811.023 8.93335 811.557 7.66667 812.623 6.60001C813.69 5.53334 814.957 5 816.423 5H919.023C920.49 5 921.757 5.53334 922.823 6.60001C923.89 7.66667 924.423 8.93335 924.423 10.4V38.6C924.423 40.0667 923.89 41.3333 922.823 42.4C921.757 43.4667 920.49 44 919.023 44H859.023V56.6H914.623C916.09 56.6 917.357 57.1333 918.423 58.2C919.49 59.2667 920.023 60.5333 920.023 62V88C920.023 89.4667 919.49 90.7333 918.423 91.8C917.357 92.8667 916.09 93.4 914.623 93.4H859.023V106Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_7_7)"/>
          <path d="M948.486 5H1065.69C1067.15 5 1068.42 5.53334 1069.49 6.60001C1070.55 7.66667 1071.09 8.93335 1071.09 10.4V42.6C1071.09 44.0667 1070.55 45.3333 1069.49 46.4C1068.42 47.4667 1067.15 48 1065.69 48H1032.49V139.6C1032.49 141.067 1031.95 142.333 1030.89 143.4C1029.82 144.467 1028.55 145 1027.09 145H987.086C985.619 145 984.353 144.467 983.286 143.4C982.219 142.333 981.686 141.067 981.686 139.6V48H948.486C947.019 48 945.753 47.4667 944.686 46.4C943.619 45.3333 943.086 44.0667 943.086 42.6V10.4C943.086 8.93335 943.619 7.66667 944.686 6.60001C945.753 5.53334 947.019 5 948.486 5Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_7_7)"/>
        </svg>
      </div>

     }

      {/* TABLET AND CELLPHONE  */}

      {isTabletOrMobile && 
      <div className="banner" id="theduet-banner-background-tablet" >
        <svg className="theduet-banner" id="theduet-logo-tablet" width="349" height="245" viewBox="0 0 349 245" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="path-1-outside-1_1_7" maskUnits="userSpaceOnUse" x="0.0148468" y="0" width="349" height="245" fill="black">
          <rect fill="white" x="0.0148468" width="349" height="245"/>
          <path d="M47.59 5H117.91C118.79 5 119.55 5.32 120.19 5.96C120.83 6.6 121.15 7.36 121.15 8.24V27.56C121.15 28.44 120.83 29.2 120.19 29.84C119.55 30.48 118.79 30.8 117.91 30.8H97.99V85.76C97.99 86.64 97.67 87.4 97.03 88.04C96.39 88.68 95.63 89 94.75 89H70.75C69.87 89 69.11 88.68 68.47 88.04C67.83 87.4 67.51 86.64 67.51 85.76V30.8H47.59C46.71 30.8 45.95 30.48 45.31 29.84C44.67 29.2 44.35 28.44 44.35 27.56V8.24C44.35 7.36 44.67 6.6 45.31 5.96C45.95 5.32 46.71 5 47.59 5Z"/>
          <path d="M185.253 5H208.773C209.653 5 210.413 5.32 211.053 5.96C211.693 6.6 212.013 7.36 212.013 8.24V85.76C212.013 86.64 211.693 87.4 211.053 88.04C210.413 88.68 209.653 89 208.773 89H185.253C184.373 89 183.613 88.68 182.973 88.04C182.333 87.4 182.013 86.64 182.013 85.76V59.84H162.573V85.76C162.573 86.64 162.253 87.4 161.613 88.04C160.973 88.68 160.213 89 159.333 89H135.813C134.933 89 134.173 88.72 133.533 88.16C132.893 87.52 132.573 86.72 132.573 85.76V8.24C132.573 7.36 132.893 6.6 133.533 5.96C134.173 5.32 134.933 5 135.813 5H159.333C160.213 5 160.973 5.32 161.613 5.96C162.253 6.6 162.573 7.36 162.573 8.24V33.44H182.013V8.24C182.013 7.36 182.333 6.6 182.973 5.96C183.613 5.32 184.373 5 185.253 5Z"/>
          <path d="M256.413 65.6H293.373C294.253 65.6 295.013 65.92 295.653 66.56C296.293 67.2 296.613 67.96 296.613 68.84V85.76C296.613 86.64 296.293 87.4 295.653 88.04C295.013 88.68 294.253 89 293.373 89H230.853C229.973 89 229.213 88.68 228.573 88.04C227.933 87.4 227.613 86.64 227.613 85.76V8.24C227.613 7.36 227.933 6.6 228.573 5.96C229.213 5.32 229.973 5 230.853 5H292.413C293.293 5 294.053 5.32 294.693 5.96C295.333 6.6 295.653 7.36 295.653 8.24V25.16C295.653 26.04 295.333 26.8 294.693 27.44C294.053 28.08 293.293 28.4 292.413 28.4H256.413V35.96H289.773C290.653 35.96 291.413 36.28 292.053 36.92C292.693 37.56 293.013 38.32 293.013 39.2V54.8C293.013 55.68 292.693 56.44 292.053 57.08C291.413 57.72 290.653 58.04 289.773 58.04H256.413V65.6Z"/>
          <path d="M8.25485 154H42.8149C54.4949 154 64.0949 156.92 71.6149 162.76C79.2149 168.52 83.0148 176.6 83.0148 187V204.88C83.0148 215.76 79.3349 224 71.9749 229.6C64.6149 235.2 54.9348 238 42.9348 238H8.25485C7.37485 238 6.61485 237.68 5.97485 237.04C5.33485 236.4 5.01485 235.64 5.01485 234.76V157.24C5.01485 156.36 5.33485 155.6 5.97485 154.96C6.61485 154.32 7.37485 154 8.25485 154ZM35.0149 177.4V214.6H42.8149C45.5349 214.6 47.6949 213.8 49.2948 212.2C50.9748 210.52 51.8148 208.08 51.8148 204.88V187C51.8148 180.6 48.8149 177.4 42.8149 177.4H35.0149Z"/>
          <path d="M145.987 154H169.507C170.387 154 171.147 154.32 171.787 154.96C172.427 155.6 172.747 156.36 172.747 157.24V205.48C172.747 216.68 169.267 225.12 162.307 230.8C155.347 236.4 145.867 239.2 133.867 239.2C121.947 239.2 112.467 236.4 105.427 230.8C98.4667 225.2 94.9867 216.76 94.9867 205.48V157.24C94.9867 156.36 95.3067 155.6 95.9467 154.96C96.5867 154.32 97.3467 154 98.2267 154H121.747C122.627 154 123.387 154.32 124.027 154.96C124.667 155.6 124.987 156.36 124.987 157.24V205.84C124.987 212 127.947 215.08 133.867 215.08C139.787 215.08 142.747 212 142.747 205.84V157.24C142.747 156.36 143.067 155.6 143.707 154.96C144.347 154.32 145.107 154 145.987 154Z"/>
          <path d="M215.924 214.6H252.884C253.764 214.6 254.524 214.92 255.164 215.56C255.804 216.2 256.124 216.96 256.124 217.84V234.76C256.124 235.64 255.804 236.4 255.164 237.04C254.524 237.68 253.764 238 252.884 238H190.364C189.484 238 188.724 237.68 188.084 237.04C187.444 236.4 187.124 235.64 187.124 234.76V157.24C187.124 156.36 187.444 155.6 188.084 154.96C188.724 154.32 189.484 154 190.364 154H251.924C252.804 154 253.564 154.32 254.204 154.96C254.844 155.6 255.164 156.36 255.164 157.24V174.16C255.164 175.04 254.844 175.8 254.204 176.44C253.564 177.08 252.804 177.4 251.924 177.4H215.924V184.96H249.284C250.164 184.96 250.924 185.28 251.564 185.92C252.204 186.56 252.524 187.32 252.524 188.2V203.8C252.524 204.68 252.204 205.44 251.564 206.08C250.924 206.72 250.164 207.04 249.284 207.04H215.924V214.6Z"/>
          <path d="M269.602 154H339.922C340.802 154 341.562 154.32 342.202 154.96C342.842 155.6 343.162 156.36 343.162 157.24V176.56C343.162 177.44 342.842 178.2 342.202 178.84C341.562 179.48 340.802 179.8 339.922 179.8H320.002V234.76C320.002 235.64 319.682 236.4 319.042 237.04C318.402 237.68 317.642 238 316.762 238H292.762C291.882 238 291.122 237.68 290.482 237.04C289.842 236.4 289.522 235.64 289.522 234.76V179.8H269.602C268.722 179.8 267.962 179.48 267.322 178.84C266.682 178.2 266.362 177.44 266.362 176.56V157.24C266.362 156.36 266.682 155.6 267.322 154.96C267.962 154.32 268.722 154 269.602 154Z"/>
          </mask>
          <path d="M47.59 5H117.91C118.79 5 119.55 5.32 120.19 5.96C120.83 6.6 121.15 7.36 121.15 8.24V27.56C121.15 28.44 120.83 29.2 120.19 29.84C119.55 30.48 118.79 30.8 117.91 30.8H97.99V85.76C97.99 86.64 97.67 87.4 97.03 88.04C96.39 88.68 95.63 89 94.75 89H70.75C69.87 89 69.11 88.68 68.47 88.04C67.83 87.4 67.51 86.64 67.51 85.76V30.8H47.59C46.71 30.8 45.95 30.48 45.31 29.84C44.67 29.2 44.35 28.44 44.35 27.56V8.24C44.35 7.36 44.67 6.6 45.31 5.96C45.95 5.32 46.71 5 47.59 5Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_1_7)"/>
          <path d="M185.253 5H208.773C209.653 5 210.413 5.32 211.053 5.96C211.693 6.6 212.013 7.36 212.013 8.24V85.76C212.013 86.64 211.693 87.4 211.053 88.04C210.413 88.68 209.653 89 208.773 89H185.253C184.373 89 183.613 88.68 182.973 88.04C182.333 87.4 182.013 86.64 182.013 85.76V59.84H162.573V85.76C162.573 86.64 162.253 87.4 161.613 88.04C160.973 88.68 160.213 89 159.333 89H135.813C134.933 89 134.173 88.72 133.533 88.16C132.893 87.52 132.573 86.72 132.573 85.76V8.24C132.573 7.36 132.893 6.6 133.533 5.96C134.173 5.32 134.933 5 135.813 5H159.333C160.213 5 160.973 5.32 161.613 5.96C162.253 6.6 162.573 7.36 162.573 8.24V33.44H182.013V8.24C182.013 7.36 182.333 6.6 182.973 5.96C183.613 5.32 184.373 5 185.253 5Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_1_7)"/>
          <path d="M256.413 65.6H293.373C294.253 65.6 295.013 65.92 295.653 66.56C296.293 67.2 296.613 67.96 296.613 68.84V85.76C296.613 86.64 296.293 87.4 295.653 88.04C295.013 88.68 294.253 89 293.373 89H230.853C229.973 89 229.213 88.68 228.573 88.04C227.933 87.4 227.613 86.64 227.613 85.76V8.24C227.613 7.36 227.933 6.6 228.573 5.96C229.213 5.32 229.973 5 230.853 5H292.413C293.293 5 294.053 5.32 294.693 5.96C295.333 6.6 295.653 7.36 295.653 8.24V25.16C295.653 26.04 295.333 26.8 294.693 27.44C294.053 28.08 293.293 28.4 292.413 28.4H256.413V35.96H289.773C290.653 35.96 291.413 36.28 292.053 36.92C292.693 37.56 293.013 38.32 293.013 39.2V54.8C293.013 55.68 292.693 56.44 292.053 57.08C291.413 57.72 290.653 58.04 289.773 58.04H256.413V65.6Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_1_7)"/>
          <path d="M8.25485 154H42.8149C54.4949 154 64.0949 156.92 71.6149 162.76C79.2149 168.52 83.0148 176.6 83.0148 187V204.88C83.0148 215.76 79.3349 224 71.9749 229.6C64.6149 235.2 54.9348 238 42.9348 238H8.25485C7.37485 238 6.61485 237.68 5.97485 237.04C5.33485 236.4 5.01485 235.64 5.01485 234.76V157.24C5.01485 156.36 5.33485 155.6 5.97485 154.96C6.61485 154.32 7.37485 154 8.25485 154ZM35.0149 177.4V214.6H42.8149C45.5349 214.6 47.6949 213.8 49.2948 212.2C50.9748 210.52 51.8148 208.08 51.8148 204.88V187C51.8148 180.6 48.8149 177.4 42.8149 177.4H35.0149Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_1_7)"/>
          <path d="M145.987 154H169.507C170.387 154 171.147 154.32 171.787 154.96C172.427 155.6 172.747 156.36 172.747 157.24V205.48C172.747 216.68 169.267 225.12 162.307 230.8C155.347 236.4 145.867 239.2 133.867 239.2C121.947 239.2 112.467 236.4 105.427 230.8C98.4667 225.2 94.9867 216.76 94.9867 205.48V157.24C94.9867 156.36 95.3067 155.6 95.9467 154.96C96.5867 154.32 97.3467 154 98.2267 154H121.747C122.627 154 123.387 154.32 124.027 154.96C124.667 155.6 124.987 156.36 124.987 157.24V205.84C124.987 212 127.947 215.08 133.867 215.08C139.787 215.08 142.747 212 142.747 205.84V157.24C142.747 156.36 143.067 155.6 143.707 154.96C144.347 154.32 145.107 154 145.987 154Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_1_7)"/>
          <path d="M215.924 214.6H252.884C253.764 214.6 254.524 214.92 255.164 215.56C255.804 216.2 256.124 216.96 256.124 217.84V234.76C256.124 235.64 255.804 236.4 255.164 237.04C254.524 237.68 253.764 238 252.884 238H190.364C189.484 238 188.724 237.68 188.084 237.04C187.444 236.4 187.124 235.64 187.124 234.76V157.24C187.124 156.36 187.444 155.6 188.084 154.96C188.724 154.32 189.484 154 190.364 154H251.924C252.804 154 253.564 154.32 254.204 154.96C254.844 155.6 255.164 156.36 255.164 157.24V174.16C255.164 175.04 254.844 175.8 254.204 176.44C253.564 177.08 252.804 177.4 251.924 177.4H215.924V184.96H249.284C250.164 184.96 250.924 185.28 251.564 185.92C252.204 186.56 252.524 187.32 252.524 188.2V203.8C252.524 204.68 252.204 205.44 251.564 206.08C250.924 206.72 250.164 207.04 249.284 207.04H215.924V214.6Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_1_7)"/>
          <path d="M269.602 154H339.922C340.802 154 341.562 154.32 342.202 154.96C342.842 155.6 343.162 156.36 343.162 157.24V176.56C343.162 177.44 342.842 178.2 342.202 178.84C341.562 179.48 340.802 179.8 339.922 179.8H320.002V234.76C320.002 235.64 319.682 236.4 319.042 237.04C318.402 237.68 317.642 238 316.762 238H292.762C291.882 238 291.122 237.68 290.482 237.04C289.842 236.4 289.522 235.64 289.522 234.76V179.8H269.602C268.722 179.8 267.962 179.48 267.322 178.84C266.682 178.2 266.362 177.44 266.362 176.56V157.24C266.362 156.36 266.682 155.6 267.322 154.96C267.962 154.32 268.722 154 269.602 154Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1_1_7)"/>
        </svg>
      </div>
      

      }



      <div className="wrapper-youtube">

        {/* <h2 className="title" id="theDuet">The Duet</h2> */}
        

        <div className="youtube-video mb-5">

          <iframe className='video-format' src="https://www.youtube.com/embed/kleScOm0t0A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" ></iframe>
          
        </div>

        <div className="youtube-video mb-5">

          <iframe className='video-format' src="https://www.youtube.com/embed/kzzV-FW8l5k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
          
        </div>
        
        <div className="youtube-video mb-5">

          <iframe className='video-format' src="https://www.youtube.com/embed/Z4IpMJXplmQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>

        </div>
      
      </div>

      <div className="bandcamp-theduet">

        <iframe style={{border: "0", width: "400px", height: "472px"}} src="https://bandcamp.com/EmbeddedPlayer/album=3255367743/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/" seamless><a href="https://panfilofiglio.bandcamp.com/album/the-duet-n-philippe-dercole-first-rain">The duet &#39;n&#39; Philippe d&#39;Ercole - First Rain by Panfilo</a></iframe>

      </div>  
      
    </div>  

  );
}
 
export default TheDuet;